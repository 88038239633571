.parent_card {
    position: relative;
}

.first-child {
    cursor: pointer;
    box-shadow: 2px 2px 8px rgba(182, 182, 182, 0.15), -2px -2px 8px rgba(182, 182, 182, 0.15);
    border: 0.5px solid #b1c5ff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    margin-bottom: 1rem;
    border-radius: 8px;
}
.second-child {
    display: none;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background: transparent !important;
    top: 30%;
    right: 0;
    border-radius: 8px;
}

.second-child_ {
    display: none;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background-color: #ffffff !important;
    padding: 1.1rem 1rem;
    top: 5;
    right: 0;
    border-radius: 2px;
    box-shadow: 0 0 0 1px rgba(110, 110, 111, 0.2);
}
.parent_card:hover .second-child {
    display: flex;
}

.parent_card:hover .second-child_ {
    display: flex;
}
.third-child {
    display: none;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    background: transparent !important;
    top: 30%;
    right: 80px;
    border-radius: 8px;
}
.parent_card:hover .third-child {
    display: flex;
}
